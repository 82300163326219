import {Box, Button} from '@material-ui/core';
import {experimentalStyled} from "@material-ui/core/styles";
import ReorderTwoToneIcon from "@material-ui/icons/ReorderTwoTone";
import Settings from "../../utils/settings";

function ReorderModule() {
    return (
        <>
            <Button size={'small'} style={{fontSize: ".6rem", paddingLeft: "8px", paddingRight: "8px", marginLeft: "4px"}}
                    variant={'contained'} onClick={async () => {await Settings.getInstance().setReorder(true); window.location.reload()}}>
                {"Riordina"}
            </Button>

        </>
    );
}

export default ReorderModule;

import {
  Box,
  Card,
  Typography,
  Container
} from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import JWTLogin from '../LoginJWT';

import { useTranslation } from 'react-i18next';
import { experimentalStyled } from '@material-ui/core/styles';

const Content = experimentalStyled(Box)(
  () => `
    display: flex;
    height: 100%;
    flex: 1;
`
);

const MainContent = experimentalStyled(Box)(
  () => `
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
`
);

function LoginCover() {
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  return (
    <>
      <Helmet>
        <title>Accedi - Studio De Sipio</title>
      </Helmet>
      <Content>
        {/*<Hidden mdDown>
          <SidebarWrapper>
            <Scrollbars autoHide>
              <SidebarContent>
                <Logo />
                <Box mt={6}>
                  <TypographyH1 variant="h1" sx={{ mb: 7 }}>
                    {t('Multiple auth methods included')}
                  </TypographyH1>
                  <Box sx={{ position: 'relative', width: 300, height: 120 }}>
                    <Tooltip arrow placement="top" title="Auth0">
                      <CardImg sx={{ width: 80, height: 80, left: -20, top: -40 }}>
                        <img width={40} alt="Auth0" src={icons['Auth0']} />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="Firebase">
                      <CardImg sx={{ width: 90, height: 90, left: 70 }}>
                        <img
                          width={50}
                          alt="Firebase"
                          src={icons['FirebaseAuth']}
                        />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="JSON Web Token">
                      <CardImg
                        sx={{ width: 110, height: 110, top: -30, left: 170 }}
                      >
                        <img width={80} alt="JSON Web Token" src={icons['JWT']} />
                      </CardImg>
                    </Tooltip>
                    <Tooltip arrow placement="top" title="AWS Amplify">
                      <CardImg sx={{ width: 70, height: 70, bottom: 0, right: -55 }}>
                        <img
                          width={50}
                          alt="Amplify"
                          src={icons['Amplify']}
                        />
                      </CardImg>
                    </Tooltip>
                  </Box>
                  <Typography variant="subtitle1" sx={{ my: 3 }}>
                    {t(
                      'Choose between JSON Web Token, Firebase, AWS Amplify or Auth0. Regular login/register functionality is also available.'
                    )}
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.primary"
                    fontWeight="bold"
                  >
                    {t('Want to switch auth methods?')}
                  </Typography>
                  <Typography variant="subtitle1">
                    {t(
                      'It only takes seconds. There is a documentation section showing how to do exactly that'
                    )}
                    .{' '}
                    <Link component={RouterLink} underline="hover" to="/docs">
                      Read docs
                    </Link>
                  </Typography>
                </Box>
              </SidebarContent>
            </Scrollbars>
          </SidebarWrapper>
        </Hidden>*/}
        <MainContent>
          <Container maxWidth="sm">
            <Card sx={{ px: 4, py: 5 }}>
              <Box textAlign="center">
                <Typography variant="h2" sx={{ mb: 1 }}>
                  {t('Accedi')}
                </Typography>
                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  sx={{ mb: 3 }}
                >
                  {t('Riempi i campi qui in basso per accedere.')}
                </Typography>
              </Box>
              {method === 'JWT' && <JWTLogin />}
              {/*<Box my={4}>
                <Typography
                  component="span"
                  variant="subtitle2"
                  color="text.primary"
                  fontWeight="bold"
                >
                  {t('Don’t have an account, yet?')}
                </Typography>{' '}
                <Link component={RouterLink} underline="hover" to="/account/register">
                  <b>Sign up here</b>
                </Link>
              </Box>*/}
              {/*method !== 'Auth0' && (
                <Tooltip
                  title={t('Used only for the live preview demonstration !')}
                >
                  <Alert severity="warning">
                    Usa l'email <b>francobelfatto@test.it</b> e la password <b>1234</b>.
                  </Alert>
                </Tooltip>
              )*/}
            </Card>
          </Container>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;

import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import axios, {sendDeleteRequest, sendGetRequest, sendPostRequest, sendPutRequest} from 'src/utils/axios';
import type { Event } from 'src/models/calendar';
import randomId from "../utils/randomId";
import Settings from "../utils/settings";
import {Message} from "../models/message";

interface CalendarState {
  events: Event[],
  isDrawerOpen: boolean;
  selectedEventId: string | null;
  selectedRange: {
    start: number;
    end: number;
    resourceId: string;
    allDay: boolean;
  } | null;
}

const initialState: CalendarState = {
  events: [],
  isDrawerOpen: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'calendar',
  initialState,
  reducers: {
    getEvents(state: CalendarState, action: PayloadAction<{ events: Event[]; }>) {
      const { events } = action.payload;

      state.events = events;
    },
    createEvent(state: CalendarState, action: PayloadAction<{ event: Event; }>) {
      const { event } = action.payload;

      state.events = [...state.events, event];
      },
    selectEvent(state: CalendarState, action: PayloadAction<{ eventId?: string; }>) {
      const { eventId = null } = action.payload;

      state.isDrawerOpen = true;
      state.selectedEventId = eventId;
    },
    updateEvent(state: CalendarState, action: PayloadAction<{ event: Event; }>) {
      const { event } = action.payload;

      state.events = _.map(state.events, (_event) => {
        if (_event.id === event.id) {
          _.assign(_event, { event });
          return event;
        }

        return _event;
      });
    },

    deleteEvent(state: CalendarState, action: PayloadAction<{ uid: number; }>) {
      const { uid } = action.payload;

      state.events = _.reject(state.events, { uid: uid });
    },
    selectRange(state: CalendarState, action: PayloadAction<{ start: number; end: number; resourceId: string, allDay: boolean; }>) {
      const { start, end, resourceId, allDay } = action.payload;

      state.isDrawerOpen = true;
      state.selectedRange = {
        start,
        end,
        resourceId,
        allDay
      };
    },
    openDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = true;
    },
    closeDrawerPanel(state: CalendarState) {
      state.isDrawerOpen = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

export const reducer = slice.reducer;

export const getEvents = (): AppThunk => async (dispatch) => {
  try {
    const response = await sendGetRequest<{events: Event[]}>("meetings");

    if (response.events) {
      if (Settings.getInstance().getReorder()) {
        const resp = { events: response.events.filter((event) => event.reorder === false)}

        dispatch(slice.actions.getEvents(resp));
      } else {
        dispatch(slice.actions.getEvents(response));
      }
    }
  } catch (err) {
    console.error(err);
  }
};

export const createEvent = (data: any, smsData: any): AppThunk => async (dispatch) => {
  try {
    const event = {
      id: randomId(),
      resourceId: data.resourceId,
      allDay: data.allDay,
      type: data.type,
      color: data.color,
      associatedColor: data.associatedColor,
      patientId: data.patientId,
      doctorId: data.doctorId,
      patientName: data.patientName,
      end: data.end,
      start: data.start,
      note: data.note,
      editable: data.editable,
      startEditable: data.startEditable,
      durationEditable: data.durationEditable,
      resourceEditable: data.resourceEditable,
      reorder: data.reorder
    };

    const response = await sendPostRequest('meetings', event);

    if (response.event) {
      if (smsData.masterEnabled) {
        console.warn("MAST ENABLED");

        if (smsData.firstEnabled) {
          const smsArr:Message[] = [];

          smsArr.push({
            eventId: event.id,
            recipient: smsData.recipient,
            date: smsData.firstSend,
            text: smsData.text,
            isSecondary: false
          })

          if (smsData.secondEnabled) {
            smsArr.push({
              eventId: event.id,
              recipient: smsData.recipient,
              date: smsData.secondSend,
              text: smsData.text,
              isSecondary: true
            })
          }

          const payload = {
            messages: smsArr
          }

          const smsResp = await sendPostRequest("messages/multiple", payload);

          console.warn("RESP SMS: ", smsResp);
        }
      }

      dispatch(slice.actions.createEvent(response));
    }
  } catch (err) {
    console.error(err);
  }
};

export const selectEvent = (eventId?: string, allDay?: boolean): AppThunk => async (dispatch) => {
  dispatch(slice.actions.selectEvent({ eventId, allDay }));
};

export const updateEvent = (eventId:string, update: any, eventUid?:number, smsData?: any): AppThunk => async (dispatch) => {
  console.warn("SMS DATA: ", smsData);

  try {
      const updatedEvent:Event = {
        uid: eventUid,
      id: eventId,
      resourceId: update.resourceId,
      allDay: update.allDay,
        type: update.type,
      color: update.color,
        associatedColor: update.associatedColor,
      patientId: update.patientId,
      doctorId: update.doctorId,
      patientName: update.patientName,
      end: update.end,
      start: update.start,
      note: update.note,
        editable: update.editable,
        startEditable: update.startEditable,
        durationEditable: update.durationEditable,
        resourceEditable: update.resourceEditable,
        reorder: update.reorder,
        enableSms: smsData.masterEnabled
    };


      console.warn("UPD: ", updatedEvent);

      const response = await sendPutRequest(`meetings/${eventUid}`, updatedEvent);

      if (response.data.result == 1) {
        if (smsData.masterEnabled && smsData.smsChanged) {
          console.warn("MAST ENABLED");

          if (smsData.firstEnabled) {
            const smsArr:Message[] = [];

            smsArr.push({
              eventId: eventId,
              recipient: smsData.recipient,
              date: smsData.firstSend,
              text: smsData.text,
              isSecondary: false
            })

            if (smsData.secondEnabled) {
              smsArr.push({
                eventId: eventId,
                recipient: smsData.recipient,
                date: smsData.secondSend,
                text: smsData.text,
                isSecondary: true
              })
            }

            const payload = {
              messages: smsArr
            }

            const smsResp = await sendPostRequest("messages/multiple", payload);


            console.warn("RESP SMS: ", smsResp);
          }
        }

        if (response.data.event) {
          dispatch(slice.actions.updateEvent({event: response.data.event}));
        }

      }
    } catch (err) {
    console.warn(err);
  }
};

export const deleteEvent = (uid: number, eventId?: string): AppThunk => async (dispatch) => {
  try {
    const response = await sendDeleteRequest(`meetings/${uid}`);

    if (response.result == 1) {
      dispatch(slice.actions.deleteEvent({ uid }));
    }
  } catch (err) {
    console.error(err);
  }
};

export const selectRange = (start: Date, end: Date, resourceId: string, allDay: boolean): AppThunk => (dispatch) => {
  dispatch(slice.actions.selectRange({
    start: start.getTime(),
    end: end.getTime(),
    resourceId: resourceId,
    allDay: allDay
  }));
};

export const openDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.openDrawerPanel());
};

export const closeDrawerPanel = (): AppThunk => (dispatch) => {
  dispatch(slice.actions.closeDrawerPanel());
};

export default slice;

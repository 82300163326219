import { Box, Hidden } from '@material-ui/core';
import HeaderSettings from './Settings';
import HeaderSearch from './Search';
import HeaderNotifications from './Notifications';

function HeaderButtons() {
  return (
    <Box sx={{ mr: 1 }}>
        {/*<HeaderSearch />*/}
      <Box sx={{ mx: .5 }} component="span">
          {/*<HeaderNotifications />*/}
      </Box>
      <Hidden smDown>
          {""}
      </Hidden>
    </Box>
  );
}

export default HeaderButtons;

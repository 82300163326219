import * as Yup from 'yup';
import type { FC } from 'react';
import { Formik } from 'formik';

import {
  Button,
  TextField,
  CircularProgress, InputAdornment, IconButton
} from '@material-ui/core';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import {useCallback, useEffect, useState} from "react";
import AuthErrorCode from "../../../../enums/AuthErrorCode";
import {validateString} from "../../../../utils/validateString";
import {validateEmail} from "../../../../utils/validateEmail";
import {Close, VisibilityOffOutlined, VisibilityOutlined} from "@material-ui/icons";

const LoginJWT: FC = () => {
  const initialFormValues = {
    email: "",
    password: "",
    touchedFields: {
      email: false,
      password: false
    },
    submit: null
  }

  const auth = useAuth();
  const isMountedRef = useRefMounted();
  const [formValues, setFormValues] = useState(initialFormValues);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [authErrorCode, setAuthErrorCode] = useState<AuthErrorCode>(AuthErrorCode.DEFAULT);

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  }

  const getEmailHelperText = () => {
    if (authErrorCode === AuthErrorCode.INVALID) {
      return "L'email o la password specificata non è corretta."
    }

    if (formValues.touchedFields.email) {
      if (formValues.email.length > 0 && !validateEmail(formValues.email)) {
        return "Questo indirizzo email non è valido";
      }

      if (formValues.email.length === 0) {
        return "Questo campo non può essere vuoto";
      }
    }

    return null;
  }

  const resetEmail = () => {
    setFormValues({...formValues, email: ""})
  }

  const handleBlur = (event) => {
    const {name} = event.target;
    const touched = formValues.touchedFields;
    touched[name] = true;
    setFormValues({...formValues, touchedFields: touched});
  }

  const handleChange = (event) => {
    const {name, value} = event.target;
    setFormValues({...formValues, [name]: value});
    setAuthErrorCode(AuthErrorCode.DEFAULT);
  }

  const validateForm = useCallback(() => {
    const vals = formValues;

    if (!validateString(vals.password) || !validateEmail(vals.email)) {
      setIsFormValid(false);
      return;
    }

    setIsFormValid(true);
  }, [formValues]);

  useEffect(() => {
    validateForm();
  }, [formValues]);

  return (
    <Formik
        initialValues={initialFormValues}
      onSubmit={async (values, { setErrors, setStatus }): Promise<void> => {
        try {
          setStatus({success: false});
          setIsSubmitting(true);
          await auth.login(formValues.email, formValues.password);

          if (isMountedRef.current) {
            setStatus({ success: true });
            setIsSubmitting(false);
          }
        } catch (err) {
          console.error(err);
          if (isMountedRef.current) {
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setIsSubmitting(false);
            if (auth.code !== -1) {
              setAuthErrorCode(auth.code);
              return;
            }

            setAuthErrorCode(AuthErrorCode.INVALID);
          }
        }
      }}
    >
      {({
        handleSubmit
      }): JSX.Element => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
              error={(authErrorCode === AuthErrorCode.INVALID) || (formValues.touchedFields.email && (formValues.email.length === 0 || !validateEmail(formValues.email)))}
            fullWidth
            margin="normal"
            autoFocus
              helperText={getEmailHelperText()}
            label={'Indirizzo email'}
            name="email"
            onBlur={handleBlur}
            onChange={handleChange}
            type="email"
            value={formValues.email}
            variant="outlined"
              InputProps={{
                endAdornment: (<InputAdornment position={"end"}>
                  <IconButton edge={"end"} color={"primary"} onClick={() => resetEmail()}>
                    {<Close/>}
                  </IconButton>
                </InputAdornment>)
              }}
          />
          <TextField
              error={(authErrorCode === AuthErrorCode.INVALID) || (formValues.touchedFields.password && formValues.password.length === 0)}
            fullWidth
            margin="normal"
            label={'Password'}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
              type={showPassword ? "text" : "password"}
            value={formValues.password}
            variant="outlined"
              InputProps={{
                endAdornment: (<InputAdornment position={"end"}>
                  <IconButton edge={"end"} color={"primary"} onClick={toggleShowPassword}>
                    {showPassword ? <VisibilityOffOutlined/> : <VisibilityOutlined/>}
                  </IconButton>
                </InputAdornment>)
              }}
          />

          <Button
            sx={{ mt: 3 }}
            color="primary"
            startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
            disabled={!isFormValid || isSubmitting}
            type="submit"
            fullWidth
            size="large"
            variant="contained"
          >
            {'Accedi'}
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default LoginJWT;

import { useRoutes } from 'react-router-dom';
import routes from './router';
import { SnackbarProvider } from 'notistack';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import itLocale from 'date-fns/locale/it';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import useScrollTop from 'src/hooks/useScrollTop';
import useAuth from 'src/hooks/useAuth';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@material-ui/core';
import {createContext} from 'react';
import Settings from "./utils/settings";

export type AdminContent = {
    reorder: boolean;
    setReorder: (c: boolean) => void;
}

export const AdminContext = createContext<AdminContent>({
    reorder: false,
    setReorder: () => {
    }
});

const App = () => {

  const auth = useAuth();
  const content = useRoutes(routes);
  useScrollTop();

  let syncSettings = Settings.getInstance();
  syncSettings.syncReorder();

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
        <CssBaseline />
        {auth.isInitialized ?
            content
            : <>auth not initialized</>}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;

import {sendGetRequest, sendPutRequest} from "./axios";

export default class Settings {

    private state = {
        reorder: {
            ID: 1,
            VALUE: false
        }
    };

    private static instance:Settings;

    private constructor() {

    }

    public static getInstance(): Settings {
        if (!Settings.instance) {
            Settings.instance = new Settings();
        }

        return Settings.instance;
    }

    public getReorder():boolean {
        console.log("REORD: ", this.state.reorder.VALUE);
        return this.state.reorder.VALUE;
    }

    public async syncReorder():Promise<void> {
            try {
                const response = await sendGetRequest(`settings/${this.state.reorder.ID}`) as any;

                if (response) {
                    this.state.reorder.VALUE = response.enabled;
                    return;
                }
            } catch (err) {
                console.log(err);
            }

            this.state.reorder.VALUE = false;
    }

    public async setReorder(newValue:boolean):Promise<void> {
        try {
            const response = await sendPutRequest(`settings/${this.state.reorder.ID}`, {enabled: newValue});

            if (response.result == 1) {
                this.state.reorder.VALUE = newValue;
                return;
            }
        } catch(err) {
            console.log("err");
        }

        this.state.reorder.VALUE = false;
    }
}

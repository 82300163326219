import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

const Overview = Loader(lazy(() => import('src/content/overview')));

// Applications

const Calendar = Loader(lazy(() => import('src/content/meetings/Calendar')));

// Management

const Doctors = Loader(lazy(() => import('src/content/management/Doctors')));
const Users = Loader(lazy(() => import('src/content/management/Users')));
// Auth

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login/Basic')));

// Status

const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));


const routes: PartialRouteObject[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'login-basic',
        element: <LoginBasic />
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element:           <Guest>
          <LoginCover />
        </Guest>
      },
      {
        path: '/',
        element: (
          <Navigate
            to="/"
            replace
          />
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'meetings',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/meetings/calendar"
            replace
          />
        )
      },
      {
        path: 'calendar',
        element: <Calendar />
      },
    ]
  },
  {
    path: 'management',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/users/list"
            replace
          />
        )
      },
      {
        path: 'users',
        children: [
          {
            path: '/',
            element: <Users />
          },
        ]
      },
      {
        path: 'doctors',
        children: [
          {
            path: '/',
            element: <Doctors />
          },
        ]
      },
    ]
  }
];

export default routes;

import axios from 'axios';

// const API_SERVER = "http://localhost:5001/api/"
const API_SERVER = "https://api.gestionale.studiodesipio.com/api/";

export const sendDeleteRequest = async(request) => {
    try {
        const resp = await axios.delete(`${API_SERVER}${request}`);
        //console.log(resp.data);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

export const sendGetRequest = async<T>(request, headers?) => {
    try {
        const resp = (headers ?
            await axios.get<T>(`${API_SERVER}${request}`, headers) :
            await axios.get<T>(`${API_SERVER}${request}`)
        );

        //console.log(resp.data);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

export const sendPostRequest = async<T=any>(request, body) => {
    try {
        const resp = await axios.post<T>(`${API_SERVER}${request}`, body);
        //console.log(resp.data);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
};

export const sendPutRequest = async(request, body) => {
    try {
        const resp = await axios.put(`${API_SERVER}${request}`, body);
        //console.log(resp.data);
        return resp.data;
    } catch (err) {
        console.error(err);
    }
}

const axiosInt = axios.create();

axiosInt.interceptors.request.use(
    (req) => {
        console.log(req);
        return req;
    },
    (err) => {
        return Promise.reject(err);
    }
);

axiosInt.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
);

export default axiosInt;

import {createContext, FC, ReactNode, useEffect, useReducer} from 'react';
import {Admin} from "../models/admin";
import axios, {sendGetRequest, sendPostRequest} from 'src/utils/axios';
import {decode, JWT_EXPIRES_IN, JWT_SECRET, sign, verify} from 'src/utils/jwt';
import PropTypes from 'prop-types';
import AuthErrorCode from "../enums/AuthErrorCode";

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  admin: Admin | null;
  code: AuthErrorCode;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    admin: Admin | null;
    code: AuthErrorCode;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    isAuthenticated: boolean;
    admin: Admin;
    code: AuthErrorCode;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type Action = InitializeAction | LoginAction | LogoutAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  admin: null,
  code: AuthErrorCode.DEFAULT
};

const setSession = (accessTkSignature: string | null, accessToken: string | null): void => {
  if (accessToken && accessTkSignature) {
    localStorage.setItem('accessToken', JSON.stringify({token: accessToken, signature: accessTkSignature}));
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const handlers: Record<string, (state: AuthState, action: Action) => AuthState> = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { isAuthenticated, admin, code } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      admin,
      code: code
    };
  },
  LOGIN: (state: AuthState, action: LoginAction): AuthState => {
    const { admin, code, isAuthenticated } = action.payload;

    return {
      ...state,
      isAuthenticated: isAuthenticated,
      admin,
      code: code
    };
  },
  LOGOUT: (state: AuthState): AuthState => ({
    ...state,
    isAuthenticated: false,
    admin: null,
    code: AuthErrorCode.DEFAULT
  })
};

const reducer = (state: AuthState, action: Action): AuthState => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        const tkFromJson = JSON.parse(accessToken);

        if (accessToken && tkFromJson && verify(tkFromJson.signature, JWT_SECRET)) {
          setSession(tkFromJson.signature, tkFromJson.token);

          const adminId = decode(tkFromJson.signature).id;

          const response = await sendGetRequest(`admin/${adminId}`, {headers: {"x-access-token": tkFromJson.token}}) as Admin;
          const {admin, code} = response;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              admin,
              code: code
            }
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              admin: null,
              code: AuthErrorCode.DEFAULT
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            admin: null,
            code: AuthErrorCode.DEFAULT
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string): Promise<void> => {
    const response = await sendPostRequest<{accessToken: string, admin: Admin, code: AuthErrorCode}>("auth/signin", {
      email,
      password
    })

    const { accessToken, admin, code } = response;

    if (code === AuthErrorCode.INVALID) {
      dispatch({
        type: 'LOGIN',
        payload: {
          isAuthenticated: false,
          admin: null,
          code: code
        }
      });
    }

    const accessTkSig = sign({id: admin.id}, JWT_SECRET, {
      expiresIn: JWT_EXPIRES_IN
    });

    setSession(accessTkSig, accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        isAuthenticated: true,
        admin,
        code: code
      }
    });
  };

  const logout = async (): Promise<void> => {
    setSession(null, null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;

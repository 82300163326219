import { ReactNode } from 'react';

import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';
import DocumentScannerTwoToneIcon from '@material-ui/icons/DocumentScannerTwoTone';
import WorkTwoToneIcon from '@material-ui/icons/WorkTwoTone';
import MarkunreadMailboxTwoToneIcon from '@material-ui/icons/MarkunreadMailboxTwoTone';
import QuestionAnswerTwoToneIcon from '@material-ui/icons/QuestionAnswerTwoTone';
import DashboardCustomizeTwoToneIcon from '@material-ui/icons/DashboardCustomizeTwoTone';
import AssignmentIndTwoToneIcon from '@material-ui/icons/AssignmentIndTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import StorefrontTwoToneIcon from '@material-ui/icons/StorefrontTwoTone';
import VpnKeyTwoToneIcon from '@material-ui/icons/VpnKeyTwoTone';
import ErrorTwoToneIcon from '@material-ui/icons/ErrorTwoTone';
import DesignServicesTwoToneIcon from '@material-ui/icons/DesignServicesTwoTone';
import SupportTwoToneIcon from '@material-ui/icons/SupportTwoTone';
import ReceiptTwoToneIcon from '@material-ui/icons/ReceiptTwoTone';
import GroupTwoToneIcon from '@material-ui/icons/GroupTwoTone';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
}

const menuItems: MenuItems[] = [
  {
    items: [
      {
        name: 'Calendario',
        icon: EventNoteTwoToneIcon,
        link: '/meetings/calendar'
      },
      {
        name: 'Pazienti',
        icon: GroupTwoToneIcon,
        link: '/management/users',
      },
      {
        name: 'Dottori',
        icon: AssignmentIndTwoToneIcon,
        link: '/management/doctors'
      }
    ]
  }
];

export default menuItems;

import { useContext } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import Logo from 'src/components/Logo';

import {Box, Button, Drawer, Grid, Hidden} from '@material-ui/core';

import { experimentalStyled } from '@material-ui/core/styles';
import SidebarMenu from './SidebarMenu';
import HeaderUserbox from "../Header/Userbox";
import ReorderModule from "../../../components/Reorder";
import Settings from "../../../utils/settings";
import LockOpenTwoToneIcon from "@material-ui/icons/LockOpenTwoTone";
import useAuth from "../../../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {LogoutTwoTone} from "@material-ui/icons";

const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: 100%;
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 64px;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;
        height: 64px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
`
);

const MuiButtonCust = experimentalStyled(Button)(
    ({theme}) => `
    &.MuiButton-root {
    margin-right: 32px;
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(14)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(4)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
    `
);

function Sidebar() {
  const { admin, logout } = useAuth();
  const navigate = useNavigate();

  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  const handleLogout = async (): Promise<void> => {
    try {
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper>
          <Scrollbars autoHide>
            <TopSection>
              <Grid container spacing={0} alignItems={"center"}>
                <Grid item xs={3}>
                  <Logo />
                </Grid>
                <Grid item xs={6}>
                  <SidebarMenu />
                </Grid>
                <Grid item xs={3} justifyContent={"flex-end"} pr={"8px"}>
                  <Box display={"flex"} justifyContent={"flex-end"}>
                    <MuiButtonCust color="primary" onClick={handleLogout}>
                      <LogoutTwoTone sx={{ mr: 1 }} />
                      {'Logout'}
                    </MuiButtonCust>
                  </Box>
                </Grid>
              </Grid>
            </TopSection>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
    </>
  );
}

export default Sidebar;
